import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditDeletePopover from '../Categories/EditDeletePopover/EditDeletePopover';

const EditableCode = ({ code, onCodeClick, onDeleteClick, onEditClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const onThreeDotsClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div key={code.code} className={classes.codeRow} onClick={() => onCodeClick(code)}>
            <div className={classes.left}>
                <h2 className={classes.codeText}>{code.code}</h2>
            </div>

            <div className={classes.right}>
                <h3 className={classes.name}>{code.name}</h3>
                <p className={classes.mail}>{code.mail}</p>
            </div>

            <div className={classes.dots} onClick={(event) => onThreeDotsClick(event)}>
                ...
            </div>
            <EditDeletePopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onEditClick={() => onEditClick(code)}
                onDeleteClick={() => onDeleteClick(code)}
            />
        </div>
    );
};

const useStyles = makeStyles({
    codeRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        border: '1px solid #ACACAC',
        borderRadius: 10,
        cursor: 'pointer',
        position: 'relative',
        flexGrow: 1,          // Allow the row to grow and take up available space
        width: '100%',        // Ensure it takes full width within the parent
        boxSizing: 'border-box',
        minWidth: '300px',    // Set a minimum width to avoid being squeezed too much
        maxWidth: 'none',     // No max-width restriction
    },
    left: {
        flex: 1,  // Take up some space for the code on the left
        textAlign: 'left',
    },
    right: {
        flex: 2,  // More space for name/mail
        display: 'flex',
        flexDirection: 'column',  // Stack name and mail vertically
        alignItems: 'flex-start',
    },
    codeText: {
        fontSize: 16,
        fontWeight: 600,
        color: '#000000',
    },
    name: {
        fontSize: 14,
        fontWeight: 600,
        margin: 0,
        color: '#000000',
    },
    mail: {
        fontSize: 12,
        fontWeight: 400,
        margin: 0,
        color: '#737373',
    },
    dots: {
        position: 'absolute',
        right: 10,
        cursor: 'pointer',
        padding: '10px'
    },
});

export default EditableCode;
