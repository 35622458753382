import React, { useState, useEffect, useContext } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import EditableCode from './EditableCode';
import categoriesExampleIcon from 'src/Assets/new/categoriesExample.png';
import createCategoryExampleIcon from 'src/Assets/new/createCategoryExample.png';
import createCategoryExampleIcon2 from 'src/Assets/new/createCategoryExample2.png';
import { makeStyles } from '@material-ui/core/styles';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import VipCodesReducer from './VipCodesReducer';
import VipPlans from './VipPlans';
import VIPCalendar from './VipCalendar';

const StateEnum = {
    MAIN: 0,
    VIP_PLANS: 1,
    VIP_CALENDAR: 2
};

const VipTraining = ({ setLoadingText }) => {

    const classes = useStyles();
    const [state, setState] = useState(StateEnum.MAIN);
    const [vipCodes, setVipCodes] = useState(null);
    const [selectedVipCode, setSelectedVipCode] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    useEffect(() => {
        if (vipCodes == null) {
            fetchVipCodes()
        }
    }, []);

    const fetchVipCodes = async () => {
        try {
            setLoadingText("Fetching codes");
            const codes = await NetworkProvider.get_vip_codes(loginCredentials, setLoginCredentials)
            setVipCodes(VipCodesReducer.sortedCodes(codes))
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    };

    const handleAddCode = async () => {
        try {
            setLoadingText("Adding Vip Code");
            const newCode = await NetworkProvider.insert_vip_code(loginCredentials, setLoginCredentials)
            setVipCodes((prevCodes) => [newCode, ...prevCodes]);
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    }

    const handleCodeClick = (vipCode) => {
        setSelectedVipCode(vipCode)
        setState(StateEnum.VIP_PLANS);
    }

    const handleCodeDelete = async (vipCode) => {
        try {
            setLoadingText("Deleting VIP Code");
            await NetworkProvider.delete_vip_code(loginCredentials, setLoginCredentials, vipCode.code)
            setVipCodes((prevCodes) => prevCodes.filter((code) => code.code !== vipCode.code));
            setLoadingText(null)
        } catch (error) {
            window.alert(error);
            setLoadingText(null)
        }
    }

    const handleCodeEdit = (vipCode) => {
        setSelectedVipCode(vipCode)
        setState(StateEnum.VIP_PLANS);
    }

    const onCloseVipPlans = () => {
        setSelectedVipCode(null);
        setState(StateEnum.MAIN);
    }

    const onCloseVipCalendar = () => {
        setSelectedPlan(null);
        setState(StateEnum.VIP_PLANS);
    }

    const onVipCodeUpdated = async (vipCode, category) => {

        try {
            const stringCategories = JSON.stringify([category]);
            console.log(stringCategories);
            await NetworkProvider.update_vipcode_categories(loginCredentials, setLoginCredentials, vipCode, stringCategories);
            setVipCodes((prevVipcodes) => {
                const existingCodeIndex = prevVipcodes.findIndex((code) => code.code === vipCode.code);

                if (existingCodeIndex !== -1) {
                    // Copy the vipCode and update its categories
                    const updatedVipCode = { ...prevVipcodes[existingCodeIndex], categories: stringCategories };

                    // Create a new array with the updated vipCode
                    const updatedVipcodes = [...prevVipcodes];
                    updatedVipcodes[existingCodeIndex] = updatedVipCode;

                    if (selectedVipCode && selectedVipCode.code === vipCode.code) {
                        // Update selectedVipCode as well
                        setSelectedVipCode(updatedVipCode);
                    }

                    return updatedVipcodes;
                }
            });
        } catch (error) {
            window.alert(error)
        }
    }

    const onPlanClick = (plan) => {
        setSelectedPlan(plan)
        setState(StateEnum.VIP_CALENDAR);
    }

    const VipCodesListContent = () => {

        if (vipCodes == null) {
            return null
        }

        if (vipCodes.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <img src={createCategoryExampleIcon2} alt="VIP user example" className={classes.categoriesExampleIcon2} />
                        <h3 className={classes.exampleTitle}>Lets add your first VIP user. Each VIP user has their own training plans</h3>
                        <BlueButton onClick={handleAddCode}>Add code</BlueButton>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h2 className={classes.mainTitle}>VIP Codes</h2>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={handleAddCode}>Add code</BlueButton>
                        </div>
                    </header>
                    <h2 className={classes.categoriesTitle}>VIP Codes</h2>
                    <div className={classes.contentArea}>
                        <div className={classes.categoryList}>
                            {vipCodes.map((code) => {
                                return <EditableCode
                                    key={code.code}
                                    code={code}
                                    onCodeClick={handleCodeClick}
                                    onDeleteClick={handleCodeDelete}
                                    onEditClick={handleCodeEdit}
                                />
                            })}
                        </div>
                        <div className={classes.exampleContainer}>
                            <h3 className={classes.exampleTitle}>VIP user example</h3>
                            <img src={categoriesExampleIcon} alt="VIP User example" className={classes.categoriesExampleIcon} />
                        </div>
                    </div>
                </div>
            )
        }
    };

    return (
        <React.Fragment>
            {state === StateEnum.MAIN && <VipCodesListContent />}
            {state === StateEnum.VIP_PLANS && selectedVipCode && <VipPlans
                vipcode={selectedVipCode}
                onClose={onCloseVipPlans}
                onPlanClick={onPlanClick}
                setLoadingText={setLoadingText}
                onVipCodeUpdated={onVipCodeUpdated}
            />}
            {state === StateEnum.VIP_CALENDAR && selectedPlan && <VIPCalendar
                vipcode={selectedVipCode}
                plan={selectedPlan}
                onVipCodeUpdated={onVipCodeUpdated}
                setLoadingText={setLoadingText}
                onClose={onCloseVipCalendar}
            />}
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000'
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
        marginRight: 20
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    headerButton: {
        marginLeft: 'auto',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%'
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    }
});

export default VipTraining;
